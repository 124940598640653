import(/* webpackMode: "eager" */ "/vercel/path0/assets/icons/inlines/blind-sheep-bg.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/icons/inlines/blind-sheep.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/icons/inlines/bopeep-logo-symbol.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/icons/inlines/bopeep-logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/icons/inlines/feather-pen.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/icons/inlines/google.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/icons/inlines/group-sheep.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/icons/inlines/linkedin.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/icons/inlines/petition-types.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/icons/inlines/semi-blind-sheep-bg.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/icons/inlines/semi-blind-sheep.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/icons/inlines/send-message.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/icons/inlines/sheep-bg.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/icons/inlines/sheep.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/images/about.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/images/anonymity.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/images/invite.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/images/landing_step1.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/images/landing_step2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/images/landing_step3.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/images/landing.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/images/scheduling.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.20_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.82.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.20_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.82.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/shared/SketchBoard/SketchBoard.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/Landing.module.scss");
